@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.10.5/font/bootstrap-icons.css");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

@font-face {
  font-family: "uknumberplate";
  src: url("../public/fonts/uknumberplate.eot");
  src: url("../public/fonts/uknumberplate.eot?#iefix")
      format("embedded-opentype"),
    url("../public/fonts/uknumberplate.woff2") format("woff2"),
    url("../public/fonts/uknumberplate.woff") format("woff"),
    url("../public/fonts/uknumberplate.ttf") format("truetype"),
    url("../public/fonts/uknumberplate.svg#uknumberplate") format("svg");
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #c0c0c0 !important;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #c0c0c0 !important;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #c0c0c0 !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #c0c0c0 !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*********/
.top-nav {
  background-color: #282828;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
}

.call-nav-left a {
  color: #e4e4e4;
  padding-right: 15px;
  text-decoration: none;
  font-family: "Rubik", Sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.call-nav-left a span {
  color: #fee100;
  padding-right: 10px;
}

.call-nav-center h3 {
  color: #e4e4e4;
  font-family: "Rubik", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}

.call-nav-right ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.call-nav-right ul span {
  background-color: #eacf00;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.call-nav-right ul span a {
  color: #000;
}

.call-nav-right ul li {
  margin-left: 10px;
}
.bg-light {
  padding: 0;
  background-image: linear-gradient(180deg, #333333 0%, #000000 100%);
}
.navbar-nav {
  align-items: center;
}

/* .navbar-brand img {
  width: 113px;
} */

.navbar-brand {
  background-color: #ffffff;
  padding: 10px 25px;
}
button.btn.btn-light.call-button {
  padding: 10px 30px;
  color: #1b1b1b;
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}

.bg-light .container-fluid {
  padding: 0;
}

.navbar .nav-link {
  font-family: "Rubik", Sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  text-transform: uppercase;
  color: #ffffff;
  text-align: center;
}

.navbar .nav-link span {
  font-weight: 500;
  color: #fee100;
}
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
  color: #fff;
}

.navbar .nav-item {
  padding: 0 20px;
  position: relative;
}
.navbar-right a {
  position: relative;
}

.navbar-right a .cart-nav {
  font-size: 30px;
  color: #000;
  position: relative;
}

.navbar-right .cart-nav-bg {
  position: absolute;
  right: -4px;
  top: 1px;
  background-color: #ceb600;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  color: #000 !important;
}

.navbar-right li.nav-item {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #e3c900;
  width: 5%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom-navbar {
  /* background-color: #CEB600; */
  padding: 10px 0;
}

.Customers-iteam {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Customers-iteam h4 {
  margin: 0;
  font-family: "Rubik", Sans-serif;
  font-size: 12px;
  font-weight: 500;
  padding-left: 10px;
}
.home-banner {
  background: #fee100;
  padding: 80px 0px;
}

.banner-serch-bar .form-group {
  position: relative;
  max-width: 719px;
  margin: 0 auto;
}

.banner-serch-bar .form-group input {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 11px 16px rgba(0, 0, 0, 0.09);
  border-radius: 11px;
  height: 82px;
  margin: 0 auto;
  text-transform: uppercase;
  padding: 0 22px;
  color: #000;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.banner-serch-bar .form-group button {
  position: absolute;
  width: 143px;
  height: 68px;
  top: 7px;

  /* Black */
  background: #000000;
  border-radius: 9px;
  right: 7px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */
  text-align: center;
  color: #fee100;
}

/* .banner-serch-bar {
  text-align: center;
} */

.banner-serch-bar h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;

  /* identical to box height, or 20px */

  /* Black */
  color: #000000;
  margin-top: 18px;
  margin-bottom: 20px;
}
.banner-serch-bar-title h2 {
  color: var(--black, #000);
  font-family: Rubik;
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  margin-bottom: 28px;
}
.home-banner .row {
  align-items: center;
}
.rating-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rating-row h2 {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 11.0673px;
  line-height: 11px;
  text-align: center;
  color: #000000;
  margin: 0;
  margin-right: 7px;
  margin-left: 7px;
  display: flex;
  align-items: center;
}

.rating-row h2 strong {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 16.6009px;
  line-height: 17px;
  /* identical to box height, or 100% */
  text-align: center;
  color: #fea500;
  margin-left: 7px;
}

.rating-row ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  align-items: center;
}

.rating-row ul li span {
  color: #fea500;
  font-size: 19px;
  padding: 0 3px;
}

.rating-row h6 {
  margin: 0;
  margin-left: 7px;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  text-align: center;

  /* Black */
  color: #000000;
}
.advancedsearch {
  background: #000000;
  padding: 30px 0px;
  text-align: center;
}

.advancedsearch h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */
  text-align: center;
  color: #ffffff;
  margin-bottom: 12px;
}

.advancedsearch h2 span {
  font-weight: 500;
}

.advancedsearch h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */
  text-align: center;
  color: #ffffff;
  margin: 0;
}

.advancedsearch h3 a {
  font-weight: 500;
  color: #ffffff;
}
.premium-title {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
}

.premium-title h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin: 0;
  margin-left: 19px;
}

.premium-registrations {
  padding: 50px 0px;
}

.premium-table {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
}

.premium-table-head ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.premium-table-head {
  background: #000000;
  padding: 15px 62px;
}

.premium-table-head ul li h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  /* identical to box height, or 18px */
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
  text-align: center;
}

.premium-table-body ul {
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding: 13px 25px;
}

.nik-button button {
  background: #fee100;
  border-radius: 8px;
  font-family: "uknumberplate";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
  padding: 14px 19px;
}

.premium-table-body ul li h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
}
.view-button button {
  /* Yellow (Pantone)

Buttom-Bg
*/

  border-radius: 5px;

  font-family: "Rubik";

  font-style: normal;

  font-weight: 400;

  font-size: 16px;

  line-height: 100%;
  /* identical to box height, or 16px */

  text-align: center;

  text-transform: capitalize;

  padding: 11px 17px;

  margin: 0 5px;
}

.view-button button.btn.btn-warning {
  border: 2px solid #000;
  color: #000000;
  background-color: transparent;
}

.view-button button.btn.btn-dark {
  background: #18afa0;
  border-color: #18afa0;
  color: #ffffff;
}

.premium-table-body ul li {
  width: 33%;
}

.view-button {
  text-align: right;
}

.premium-table-head ul li {
  width: 33%;
}

.premium-table-head ul:first-child li {
  text-align: left;
}

.premium-table-head ul li:first-child h2 {
  text-align: left;
}

.premium-table-head ul li:last-child h2 {
  text-align: right;
}

.premium-table-body ul:last-child {
  border: 0;
}
.dark-bgPremiumRegistrations {
  background: #f8f8f8;
}

.footer {
  padding: 60px 30px;
  background-image: linear-gradient(180deg, #333333 0%, #000000 100%);
}

.quick-links h2 {
  font-family: "Rubik", Sans-serif;
  font-size: 22px;
  font-weight: 600;
  color: #fee100;
  margin-bottom: 20px;
}

.footer-logo p {
  font-family: "Rubik", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #d7d7d7;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-logo ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-logo ul li span {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  margin: 0 3px;
}
.color-blu {
  background-color: #3b5998;
}

.color-light-blu {
  background-color: #1da1f2;
}

.color-dark {
  background-color: #262626;
}

.color-light-dark {
  background-color: #0077b5;
}

.color-red {
  background-color: #bd081c;
}

.footer-logo {
  text-align: center;
}

.footer-logo img {
  width: 185px;
}

.quick-links ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.quick-links ul li a {
  font-family: "Rubik", Sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #d7d7d7;
  text-decoration: none;
  line-height: 36px;
}

.call-of-us a {
  color: #d7d7d7;
  text-decoration: none;
}

.call-of-us a span {
  padding-right: 10px;
  color: #fee100;
}

.call-of-us {
  margin-top: 20px;
}
.copy-right-footer {
  background-color: #000000;
  border-style: solid;
  border-width: 1px 0px 0px 0px;
  border-color: #ffffff12;
  text-align: center;
  padding: 30px 0px;
}

.visa-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.visa-list li {
  border-radius: 8px;
  overflow: hidden;
  margin: 0 15px;
}

.copy-right-footer a {
  color: #e4e4e4;
  font-family: "Rubik", Sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-decoration: none;
  margin-bottom: 20px;
  display: block;
}

.copy-right-footer p {
  margin: 0;
  color: #e4e4e4;
  font-family: "Rubik", Sans-serif;
  font-size: 12px;
  font-weight: 400;
}

/* .bottom-footer-bg .bottom-navbar {
  background-color: #fee100;
} */
/******view page******/
.view-form {
  background: #ffffff;
  border-width: 1px 1px 8px 1px;
  border-style: solid;
  border-color: #eaeaea;
  border-radius: 18px;
  padding: 30px;
}

.view-form-head h2 {
  background: #fee100;
  border: 1px solid #dbc839;
  box-shadow: 0px 6px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px 8px 0px 0px;
  font-weight: 600;
  font-size: 56px;
  line-height: 100%;
  /* identical to box height, or 56px */
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
  font-family: "uknumberplate";
  margin: 0;
  padding: 20px 0px;
}

.view-form-head p {
  background: #0c0c0c;
  border-radius: 0px 0px 4px 4px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 27px;
  /* or 150% */
  text-align: center;
  text-transform: capitalize;
  color: #ffffff;
  margin: 0;
  padding: 15px 0px;
}

.view-form-head p span {
  font-weight: 500;
}

.view-form-body ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.view-form-body ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f7f7;
  border: 1px solid #dfdfdf;
  padding: 17px 20px;
}

.view-form-body ul li h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin: 0;
}

.view-form-body ul li span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height, or 150% */
  text-align: center;
  text-transform: capitalize;

  /* Body */
  color: #333333;
}

.view-form-body .total-free {
  background: #ffffff;
}

.view-form-body .total-free h2 {
  font-weight: 600;
  font-size: 24px;
}

.view-form-body .total-free span {
  font-size: 24px;
}

.view-button-form button {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  text-transform: capitalize;
  padding: 21px 20px;
  width: 100%;
  margin: 0 10px;
}

.view-button-form a {
  width: 49%;
}
.view-button-form {
  display: flex;
  align-items: self-end;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.visa-img {
  text-align: center;
}

.view-banner {
  background: #f8f8f8;
  padding-top: 64px;
  padding-bottom: 0px;
}

.view-banner .row {
  align-items: center;
}

.audi-img {
  position: relative;
  text-align: center;
}

.audi-img img {
  width: 100%;
}

.audi-img h3 {
  font-size: 1.95rem;
  line-height: 1;
  white-space: nowrap;
  color: #000 !important;
  font-weight: 400;
  font-family: "uknumberplate";
  position: absolute;
  text-align: center;
  left: 37.5%;
  top: 66.68%;
  right: 37%;
  bottom: 24%;
}

.view-button-form button.btn.btn-warning {
  background: #fee100;
  border-color: #fee100;
}

.view-button-form button.btn.btn-dark {
  background: #000000;
  border-color: #000000;
}
.management-plate {
  background: #000000;
  padding: 100px 0px;
}

.management-card h2 {
  background-image: url("../public/img/tengle.png");
  background-size: cover;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */

  /* Black */
  color: #000000;
  width: 478px;
  padding: 23px 0px;
  margin: 0;
  padding-left: 30px;
}

.management-card-box {
  background: #272727;
  border: 1px solid #454545;
  border-radius: 0px 13px 13px 13px;
  padding: 30px 35px;
}

.management-card-box p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 31px;
  color: #ffffff;
  margin: 0;
}

.management-card-box ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  border-top: 1px solid #454545;
  padding-top: 25px;
  margin-top: 30px;
}

.management-card-box ul li a {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */
  text-align: center;
  text-decoration-line: underline;
  color: #ffffff;
  margin-right: 54px;
}

.management-card-box ul li a span {
  color: #fee100;
  padding-left: 9px;
}
.customer-gallery {
  background: #f8f8f8;
  padding: 68px 0px;
}

.customer-card {
  background: #ffffff;
  box-shadow: 0px 20px 32px rgba(0, 0, 0, 0.08);
  border-radius: 16px;
  padding: 32px;
}

.customer-star {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.customer-star li {
  color: #fcd503;
  margin-right: 4px;
}

.customer-card h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height, or 150% */
  color: #a6a6a6;
  margin-bottom: 4px;
}

.customer-card h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #4285f4;
  text-decoration: none;
}
.customer-card h3 a {
  text-decoration: none;
}

.customer-card p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  /* or 69% */

  /* Body */
  color: #333333;
  margin: 0;
  margin-bottom: 13px;
  margin-top: 13px;
}

.accordion {
  border: 0;
  border-radius: 0;
}

.accordion-item:first-of-type {
  border-radius: 0;
  border: 0;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-radius: 0;
}

.accordion-item:last-of-type {
  border-radius: 0;
}

.accordion-item {
  margin-bottom: 20px;
}

.accordion-button:not(.collapsed) {
  background: #fee100;
  border-color: #fee100;
}

.accordion-button:not(.collapsed) {
  background: #fee100;
  border-color: #fee100;
  border-radius: 8px 8px 0px 0px !important;
}

.accordion-item {
  border: 0;
}

.accordion-body {
  background: #ffffff;
  border: 1px solid #ededed;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.07);
  margin-top: 10px;
  border-radius: 0px 0px 10px 10px;
}
.accordion-button {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  /* identical to box height, or 24px */
  color: #111111;
  background: linear-gradient(133.98deg, #f2f2f2 0%, #f2f2f2 100%);
  border: 1px solid #dedede;
  border-radius: 10px !important;
}
.accordion-body p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  /* or 156% */

  /* Body */
  color: #333333;
}
.about-page {
  background: #fff;
  padding: 60px 0px;
}

.about-title {
  text-align: center;
  margin-bottom: 47px;
}

.about-title h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */
  text-align: center;
  text-transform: capitalize;
  color: #000000;
}

/***sell page*****/
.sell-page {
  background: #f8f8f8;
  padding: 60px 0px;
}

.sell-card-row h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */
  text-transform: capitalize;
  color: #000000;
}

.sell-card-row p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 26px;
}

.sell-card-row p span {
  font-weight: 600;
}

.sell-card-row ul {
  margin-bottom: 26px;
}

.sell-card-row ul li p {
  margin-bottom: 14px;
}

.sell-card-row h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  /* identical to box height */
  text-transform: capitalize;
  color: #000000;
}

.sell-registration-form {
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  border-radius: 18px;
  padding: 35px 23px;
}
.sell-registration-form h1 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 28px;
}
.sell-registration-form h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;

  color: #000000;
}
.general-form {
  margin-top: 20px;
}

.general-form h2 {
  margin-top: 20px;
}
.general-form .form-group {
  margin-bottom: 15px;
}
.note-checkbox.bottom-bt {
  margin-bottom: 20px;
}

.sell-registration-form .form-group input {
  height: 50px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
}

.sell-registration-form .form-group label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 12px;
}

.listing-price .input-group-text {
  width: 61px;
  height: 50px;
  background: #efefef;
  border: 1px solid #d6d6d6;
  border-radius: 8px 0px 0px 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 23px;
  text-transform: capitalize;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.listing-price {
  max-width: 186px;
}
.last-bt {
  margin-top: 20px;
  margin-bottom: 20px;
}
.use-serch {
  padding: 0;
  margin: 0;
  list-style: none;
}

.use-serch li {
  display: flex;
}

.use-serch li span {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  text-transform: capitalize;
  color: #666666;
  padding-right: 10px;
}

.use-serch li h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-transform: capitalize;
  color: #666666;
  margin: 0;
}

.last-bt label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 12px;
}

.sell-registration-form p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* or 150% */
  text-transform: capitalize;
  color: #666666;
}
.note-checkbox {
  margin-top: 20px;
  margin-bottom: 28px;
}

.note-checkbox ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.note-checkbox ul label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  text-transform: capitalize;
  color: #666666;
  margin-left: 10px;
  margin-top: 6px;
}

.note-checkbox ul li {
  margin-bottom: 2px;
}

.note-checkbox .form-check-input {
  width: 24px;
  height: 24px;
  /* background: #FFFFFF; */
  border: 1px solid #d6d6d6;
  border-radius: 6px;
}

.Permission-title h2 {
  margin-top: 0;
  margin-bottom: 22px;
}
.note-checkbox.bottom-bt label {
  margin-top: 0;
}
.submith-button-list button {
  background: #fee100;
  padding: 21px 71px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  /* identical to box height, or 20px */
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  width: 100%;
  margin-bottom: 24px;
}

.submith-button-list p a {
  font-weight: 500;
  color: #000000;
}
.submith-button-list p {
  margin: 0;
}
.your-own {
  margin-top: 50px;
}
.view-number {
  text-align: center;
  margin-top: 60px;
}

.view-number h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 38px;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  margin: 0;
  margin-bottom: 30px;
}

.view-number .got-button {
  background: #fee100;
  border-radius: 8px;
  font-family: "uknumberplate";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
  padding: 20px 50px;
}

.view-number h3 {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
  color: #000000;
  margin: 0;
  margin-bottom: 15px;
  margin-top: 25px;
}

.view-number h3 span {
  padding-left: 8px;
  color: #959191;
}

.view-number .view-button {
  background: #fee100;
  border-radius: 8px;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #000000;
  padding: 15px 40px;
}
/*********checkout page start************/
.secure-tabs .accordion-button span {
  background: #4285f4;
  width: 64px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f8f8f8;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-right: 37px;
}

.secure-tabs .accordion-button {
  padding: 0;
  overflow: hidden;
  color: #000;
  background: #fff;
  border: 0;
  box-shadow: unset;
}

.SecureCheckout-tabs {
  margin-top: 50px;
  margin-bottom: 60px;
}

.secure-tabs .accordion-button::after {
  display: none;
}

.secure-tabs .accordion-item {
  margin-bottom: 12px;
  background: #fff;
  border: 1px solid #dfdfdf;
  overflow: hidden;
  border-radius: 12px;
}

.secure-tabs .accordion-body {
  border-top: 1px solid #dfdfdf !important;
  background: #fff;
  box-shadow: unset;
  border-radius: 0;
  margin-top: 0;
  padding: 30px;
}

body {
  background: #f8f8f8;
}
.biling-form .form-control {
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  height: 54px;
  color: #000;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.biling-form label {
  color: #000;
  font-family: Rubik;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 8px;
}

.biling-form .form-select {
  border-radius: 8px;
  border: 1px solid #d6d6d6;
  height: 54px;
  color: #a6a6a6;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.biling-form .form-group {
  margin-bottom: 28px;
}

.biling-form h2 {
  color: #000;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  margin-bottom: 24px;
}

.back-button button.btn.btn-warning {
  background-color: #fee100;
  border-color: #fee100;
}

.biling-form .biling-title label {
  color: #666;
  font-size: 14px;
  margin-left: 15px;
  margin-bottom: 0;
}

.biling-form .biling-title .form-check input {
  border-radius: 6px;
  border: 1px solid #d6d6d6;
  width: 24px;
  height: 24px;
}

.back-button {
  margin-top: 24px;
  display: flex;
}

.optional-extras h2 {
  color: #000;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.optional-extras .optional-button {
  margin-top: 40px;
}

.optional-extras .optional-button input {
  width: 24px;
  height: 24px;
  margin-top: 0;
}

.optional-extras .optional-button label {
  color: #000;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
  margin-left: 16px;
}

.optional-extras .optional-button p {
  color: #666;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-transform: capitalize;
  margin: 0;
  margin-top: 16px;
}

.optional-service {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optional-extras .optional-button h3 {
  color: #000;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
  margin: 0;
}

.optional-button-list ul {
  padding: 0;
  margin: 0;
  color: #666;
  font-family: Rubik;
  font-size: 14px;
  text-transform: capitalize;
  margin-left: 15px;
}

.optional-button-list {
  margin-top: 16px;
}

.back-button button.btn.btn-dark.collapsed {
  background: #000;
  color: #fff;
}

.back-button button {
  padding: 21px 71px;
  text-align: center;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
  width: 100%;
  margin: 0 5px;
}
.back-button button span {
  margin: 0 7px;
}

.optional-button-list ul li p {
  line-height: 20px;
  color: #666;
  font-size: 14px;
  margin: 0;
}
.order-summary-title h2 {
  color: #000;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
}

.order-summary-list {
  list-style: none;
  border-top: 4px solid #dfdfdf;
  border-bottom: 4px solid #dfdfdf;
  padding: 16px 0px;
  margin: 30px 0px;
}

.order-summary-list li h3 {
  color: #333;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  margin: 0;
}

.order-summary-list li h3 span {
  font-weight: 400;
}

.order-summary-list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  padding: 16px 0px;
}

.order-summary-list li h2 {
  color: #000;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.order-summary-list li h3 a {
  color: #4285f4 !important;
  text-decoration: none;
  margin-left: 5px;
  cursor: pointer;
}

.order-summary-list li:last-child {
  border: 0;
}

.debit-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 40px;
}

.debit-card .form-check input {
  width: 24px;
  height: 24px;
  margin-top: 0;
  margin-right: 16px;
}

.debit-card .form-check label {
  color: #000;
  font-family: Rubik;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  text-transform: capitalize;
}

.debit-numbar ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #34a853;
  background: #f3fff6;
  height: 54px;
  margin-bottom: 40px;
}

.debit-numbar ul li {
  width: 50%;
  display: flex;
}

.debit-numbar ul li input {
  height: 54px;
  color: #a6a6a6;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  padding: 0 15px;
  border: 0;
  background-color: transparent;
  outline: none;
}

.debit-card-img img {
  width: 100%;
}
.order-checkout-card {
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  background: #fff;
  padding: 24px;
}

.order-checkout-card-title h2 {
  border-radius: 8px;
  background: #fee100;
  color: #000;
  text-align: center;
  font-family: uknumberplate;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
  padding: 12px 0;
  margin-bottom: 30px;
}

.order-checkout-card-title p {
  color: #333;
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
  margin: 0;
}

.order-checkout-card-title p span {
  font-weight: 600;
}

.order-checkout-card-title-img img {
  width: 100%;
}
/* input:-webkit-autofill{
  -webkit-text-fill-color: #A6A6A6 !important;
 
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
   
} */
/*********checkout page end************/
/*********thanks page start************/
.thankyou-page {
  background-color: #fcfdfc;
  padding: 80px 0px;
}

.thank-content {
  text-align: center;
}

.thank-content p {
  color: #454545;
  font-family: Rubik;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;

  text-transform: capitalize;
}

.thank-content h2 {
  color: #454545;
  font-family: Rubik;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  margin: 30px 0px;
}

.thank-content img {
  width: 250px;
}
.make-offer-form {
  background: #ffffff;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.12);
  border-radius: 18px;
  padding: 35px 23px;
}

.regenquiry-page {
  margin: 60px 0px;
}

.make-offer-title h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 28px;
}

.make-offer-form .input-group.listing-price {
  max-width: initial;
}

.reasonable-offers {
  margin-top: 15px;
}

.reasonable-offers li h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 12px;
}

.reasonable-offers li p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  text-transform: capitalize;
  color: #666666;
}

.make-offer-form .form-group {
  margin-bottom: 15px;
}

.make-offer-form .form-group select {
  height: 50px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #a6a6a6;
}

.make-offer-form .form-group .form-control {
  height: 50px;
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000;
}
.contant-perimisson p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #666666;
}

.contant-perimisson h3 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 15px;
}

.contant-perimisson .form-check label {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  text-transform: capitalize;
  color: #666666;
  margin-left: 13px;
}

.offer-button {
  text-align: center;
  margin-top: 20px;
}

.offer-button button.btn.btn-warning {
  background: #fee100;
  padding: 21px 71px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 100%;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  width: 100%;
  margin-bottom: 24px;
}

.offer-button p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  text-transform: capitalize;
  color: #666666;
}

.offer-button p a {
  text-decoration: none;
}

.contant-perimisson .form-check .form-check-input {
  width: 24px;
  height: 24px;
  border: 1px solid #d6d6d6;
  margin-top: 2px;
}

.contact-numbar h2 {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 38px;
  text-transform: capitalize;
  color: #000000;
  margin-bottom: 20px;
}

.contact-numbar p {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  color: #333333;
  margin-bottom: 26px;
}

.numbar-button {
  font-family: uknumberplate;
  background: #fee100;
  padding: 15px 71px;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 100%;
  text-align: center;
  text-transform: capitalize;
  color: #000000;
  width: 100%;
  margin-bottom: 24px;
  border-radius: 6px;
}
.view-more-button {
  text-align: center;
  margin-top: 50px;
}

.view-more-button button {
  background: #000000;
  border-color: #000000;
  color: #ffffff;
  border-radius: 5px;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  text-transform: capitalize;
  padding: 12px 30px;
  margin: 0 5px;
}
/*********thanks page end************/
/**********loader start*********/
.loading img {
  text-align: center;
  width: 100px;
  position: absolute;
  top: 250px;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  z-index: 9999;
}

.loading-text {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100px;
  line-height: 100px;
}
.loading-text span {
  display: inline-block;
  margin: 0 5px;
  color: #fee100;
  font-family: "uknumberplate";
  font-size: 70px;
  letter-spacing: 20px;
}
.loading-text span:nth-child(1) {
  filter: blur(0px);
  -webkit-animation: blur-text 0.5s 0s infinite linear alternate;
  animation: blur-text 0.5s 0s infinite linear alternate;
}
.loading-text span:nth-child(2) {
  filter: blur(0px);
  -webkit-animation: blur-text 0.5s 0.2s infinite linear alternate;
  animation: blur-text 0.5s 0.2s infinite linear alternate;
}
.loading-text span:nth-child(3) {
  filter: blur(0px);
  -webkit-animation: blur-text 0.5s 0.4s infinite linear alternate;
  animation: blur-text 0.5s 0.4s infinite linear alternate;
}
.loading-text span:nth-child(4) {
  filter: blur(0px);
  -webkit-animation: blur-text 0.5s 0.6s infinite linear alternate;
  animation: blur-text 0.5s 0.6s infinite linear alternate;
}
.loading-text span:nth-child(5) {
  filter: blur(0px);
  -webkit-animation: blur-text 0.5s 0.8s infinite linear alternate;
  animation: blur-text 0.5s 0.8s infinite linear alternate;
}
.loading-text span:nth-child(6) {
  filter: blur(0px);
  -webkit-animation: blur-text 0.5s 1s infinite linear alternate;
  animation: blur-text 0.5s 1s infinite linear alternate;
}
.loading-text span:nth-child(7) {
  filter: blur(0px);
  -webkit-animation: blur-text 0.5s 1.2s infinite linear alternate;
  animation: blur-text 0.5s 1.2s infinite linear alternate;
}

@-webkit-keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(4px);
  }
}

@keyframes blur-text {
  0% {
    filter: blur(0px);
  }
  100% {
    filter: blur(0px);
  }
}
.car-img img {
  width: 100%;
}
.navbar-brand img {
  width: 130px;
}

/**********loader end*********/
.plate_no-card {
  border-radius: 12px;
  border: 1px solid #dfdfdf;
  background: #fff;
  margin-bottom: 30px;
}

.plate_no-detals {
  display: flex;
  padding: 10px 15px;
  justify-content: space-between;
}

.plate_no-card h2 {
  color: #000;
  text-align: center;
  font-family: "uknumberplate";
  font-size: 55px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-transform: capitalize;
  margin: 0;
  background: #fee100;
  padding: 20px 20px;
  border-radius: 8px 8px 0 0;
}

.plate_no-detals h4 {
  margin: 0;
  color: #000;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}

.plate_no-detals a {
  margin: 0;
  color: #000;
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  text-decoration: none;
}

.featured-page {
  margin-top: 70px;
  margin-bottom: 50px;
}

.promoTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
}

.promofield {
  width: 100%;
}

.promo-input {
  display: flex;
  align-items: center;
}

.promo-input input.form-control {
  max-width: 230px;
  height: 40px;
}

button.btn.apply-btn {
  font-weight: 600;
  background: #fee100 !important;
  margin-left: 10px;
  height: 40px;
  width: 75px;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thank-content a {
  font-size: 22px;
  margin: 20px 0;
  display: block;
  font-family: Rubik;
}
.home-content-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.home-content-btns a {
  display: flex;
  align-items: center;
  margin: 0 10px;
  text-decoration: none;
  background: #fee100;
  padding: 4px 15px;
  border-radius: 4px;
  color: #000;
  font-weight: 600;
  font-size: 16px;
}
.see-all-button {
  text-align: center;
  margin-top: 30px;
}

.see-all-button a {
  text-decoration: none;
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 38px;
  text-align: center;
  text-transform: capitalize;
}
.optional-button.standard-list label {
  font-weight: 400;
}

.optional-button.standard-list h2 {
  margin-bottom: 20px;
}

.dropdown-content-menu {
  position: absolute;
  transition-duration: 0.5s;
  z-index: 9;
}

.dropdown-content-menu ul {
  margin: 0;
  list-style: none;
  background-color: #000000;
  padding: 10px 20px;
  width: 170px;
}

.dropdown-content-menu ul li a {
  font-family: "Rubik", Sans-serif;
  font-size: 0.9em;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  padding: 10px 0;
  display: block;
}
.dropdown-content-menu ul li a:hover {
  color: #fee100;
}
.drop-icon {
  position: absolute;
  top: 20px;
  right: -4px;
  color: #fff;
  font-size: 0.7em;
}

.your-container {
  position: relative;
}

.your-dropdown-content {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  z-index: 1;
}

.your-trigger {
  cursor: pointer;
}
a.nav-link.phone {
  display: none;
}
.trust-row {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
}
.trust-row  ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.trustpilot-section {
  background-color: #fff;
  padding: 50px 0px;
}
.view-button-form.outline button.btn.btn-dark {
    border: 2px solid #000;
    background-color: transparent;
    color: #000;
}


.premium-table-body ul li:nth-child(2) {
    width: 10%;
}

.premium-table-body ul li:last-child {
    width: 50%;
}

.premium-table-head ul li:nth-child(2) {
    width: 10%;
}

.premium-table-head ul li:last-child {
    width: 50%;
}
.interest-rates-section {
  margin-top: 50px;
}

.interest-rates-section .terms-rate-options {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 12px;
  color: #333333;
  margin: 0;
  margin-bottom: 13px;
  margin-top: 10px;
  line-height: inherit;
}
.interest-rates-section .terms-rate-options ul {
  padding-left: 19px;
  margin-bottom: 8px;
}
.interest-rates-section .interest-title {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: inherit;
  color: #111111;
}
.interest-rates-section .terms-rate-options ul p {
  margin: 0;
}
/*************responsive start**********/
@media screen and (min-device-width: 320px) and (max-device-width: 767px) {
  .interest-rates-section .terms-rate-options {
    line-height: 20px;
}

.interest-rates-section .terms-rate-options p {
    margin: 0;
}

.interest-rates-section .terms-rate-options ul {
    margin-bottom: 10px;
}
  .premium-table-body ul li:last-child {
    width: 33%;
}

.premium-table-head ul li:last-child {
    width: 33%;
}

.premium-table-head ul li:nth-child(2) {
    width: 333%;
}

.premium-table-body ul li:nth-child(2) {
    width: 33%;
}
  .col-md-6.first {
    display: none;
}
  .dropdown-content-menu ul {
    width: 100%;
    background-color: transparent;
}
.navbar .your-trigger .nav-item {
  padding: 0;
}
  a.nav-link.destop {
    display: none;
  }
  .navbar .nav-link br {
    display: none;
}

.navbar .nav-link span {
    padding-right: 5px;
    color: #fff;
}
  a.nav-link.phone {
    display: block;
  }
  .drop-icon {
    top: 6px;
    left: 136px;
}
  .your-container {
    width: 100%;
  }
  .navbar .nav-link {
    text-align: left;
  }
  .navbar-nav {
    align-items: flex-start;
  }
  .dropdown-toggle-menu.destop {
    display: none;
  }
  .dropdown-content-menu {
    position: relative;
  }
  .plate_no-card h2 {
    font-size: 25px;
    padding: 15px 15px;
  }

  .plate_no-detals h4 {
    font-size: 16px;
  }

  .plate_no-detals a {
    font-size: 16px;
  }

  .plate_no-card {
    margin-bottom: 16px;
  }

  .featured-page {
    margin-top: 50px;
  }

  .loading-text span {
    font-size: 30px;
    letter-spacing: 10px;
  }

  .car-img {
    margin-top: 30px;
  }

  .banner-serch-bar-title h2 {
    font-size: 30px;
    line-height: inherit;
  }
  .top-nav {
    flex-direction: column;
  }
  .view-number h2 {
    font-size: 25px;
  }
  .view-number .got-button {
    padding: 15px 30px;
    font-size: 40px;
  }

  .call-nav-center {
    text-align: center;
    margin: 16px 2px;
    display: none;
  }
  .call-nav-right ul {
    display: none;
  }
  .navbar-toggler {
    margin-right: 12px;
    background-color: #fff;
  }
  .navbar-brand img {
    width: 110px;
  }

  .sell-card-row h3 {
    font-size: 26px;
  }

  .Customers-iteam {
    flex-wrap: wrap;
  }

  .navbar-right li.nav-item {
    position: relative;
    text-align: center;
    left: 0;
    margin: 0 auto;
    width: 70px;
    border-radius: 50%;
    height: 70px;
  }
  .navbar-right .cart-nav-bg {
    right: -23px;
    top: -4px;
  }
  .navbar-nav {
    padding-top: 20px;
  }
  .navbar-nav {
    padding-top: 20px;
    padding-bottom: 30px;
  }

  .navbar .nav-link {
    font-size: 12px;
    padding: 5px 0;
  }
  .banner-serch-bar .form-group input {
    height: 48px;
    font-size: 14px;
    width: 100%;
  }
  .banner-serch-bar .form-group {
    max-width: 100%;
  }

  .banner-serch-bar .form-group button {
    height: 48px;
    font-size: 16px;
    position: relative;
    width: 100%;
    margin: 0 auto;
    display: block;
    margin-right: 0;
    left: 0;
    right: 0;
  }

  .home-banner {
    padding: 50px 0px;
  }

  .rating-row {
    flex-wrap: wrap;
  }

  .premium-title h2 {
    font-size: 20px;
  }

  .footer {
    padding: 30px 15px;
  }

  .quick-links {
    text-align: center;
    margin-top: 15px;
  }

  .premium-table-body ul {
    padding: 8px 10px;
  }

  .nik-button button {
    font-size: 16px;
    padding: 14px 10px;
  }

  .view-button button {
    font-size: 13px;
    padding: 11px 11px;
    margin-bottom: 7px;
  }

  .view-button {
    text-align: center;
  }

  .premium-table-body ul li h3 {
    font-size: 18px;
  }

  .premium-table-head {
    padding: 15px 25px;
  }

  .premium-registrations {
    padding: 50px 0px;
  }

  .premium-table-head ul li h2 {
    font-size: 16px;
  }
  .audi-img img {
    width: 100%;
  }

  .visa-img img {
    width: 100%;
  }

  .management-card h2 {
    width: auto;
    font-size: 15px;
    padding-left: 17px;
    background-size: 100% 100%;
    position: relative;
    top: 9px;
  }

  .view-banner {
    padding-top: 50px;
    padding-bottom: 0px;
  }

  .view-form {
    padding: 15px;
  }

  .view-form-head h2 {
    font-size: 35px;
  }

  .view-form-head p {
    font-size: 16px;
    line-height: inherit;
    padding: 15px 14px;
  }
  .view-form-body ul li h2 {
    font-size: 14px;
  }

  .view-form-body ul li span {
    font-size: 15px;
  }

  .view-form-body .total-free span {
    font-size: 17px;
  }

  .view-form-body .total-free h2 {
    font-size: 17px;
  }

  .view-form-body ul li {
    padding: 14px 20px;
  }

  .view-button-form button {
    padding: 17px 18px;
    font-size: 16px;
  }

  .audi-img h3 {
    font-size: 16px;
  }

  .audi-img {
    margin-top: 30px;
  }

  .management-card {
    margin-bottom: 30px;
  }

  .management-card-box {
    padding: 20px 18px;
  }

  .management-card-box p {
    font-size: 15px;
  }

  .management-card-box ul li a {
    font-size: 13px;
    margin-right: 9px;
  }

  .management-plate {
    padding: 50px 0px;
  }

  .management-card-box ul {
    margin-top: 15px;
    padding-top: 17px;
  }

  .customer-card {
    margin-bottom: 20px;
    padding: 20px;
  }

  .about-title h2 {
    font-size: 25px;
  }

  .about-page {
    padding: 50px 0px;
  }

  .accordion-button {
    font-size: 18px;
    line-height: inherit;
  }

  .customer-gallery {
    padding: 50px 0px;
  }

  .visa-list li {
    margin: 0 9px;
  }

  .about-title {
    margin-bottom: 25px;
  }
  .submith-button-list button {
    padding: 21px 67px;
    font-size: 16px;
  }

  .sell-registration-form h1 {
    font-size: 25px;
  }

  .sell-card-row h2 {
    font-size: 25px;
  }
  .sell-registration-form .form-group input {
    font-size: 13px;
  }
  /*********checkout********/
  .secure-tabs .accordion-body {
    padding: 30px 15px;
  }

  .biling-form .form-control {
    height: 45px;
  }

  .biling-form .form-group {
    margin-bottom: 15px;
  }

  .biling-form h2 {
    font-size: 18px;
  }

  .biling-form .biling-title label {
    font-size: 13px;
  }

  .back-button button {
    padding: 12px 6px;
    font-size: 17px;
  }

  .optional-extras h2 {
    font-size: 18px;
  }

  .optional-extras .optional-button label {
    font-size: 15px;
    margin-left: 7px;
  }

  .optional-extras .optional-button {
    margin-top: 25px;
  }

  .optional-extras .optional-button h3 {
    font-size: 14px;
  }

  .order-summary-title h2 {
    font-size: 18px;
  }

  .order-summary-list li {
    padding: 10px 0px;
  }

  .order-summary-list {
    margin: 20px 0px;
  }

  .debit-card .form-check label {
    font-size: 12px;
  }

  .debit-card .form-check {
    width: 413px;
  }

  .debit-card .form-check input {
    margin-right: 10px;
  }

  .order-checkout-card-title h2 {
    font-size: 30px;
  }

  .biling-form .form-select {
    height: 45px;
  }

  .secure-tabs .accordion-button span {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    font-size: 20px;
  }
  .contact-numbar h2 {
    font-size: 20px;
  }

  .make-offer-title h2 {
    font-size: 25px;
  }

  .about-accordion .accordion-button {
    display: block;
    position: relative;
    padding-right: 40px;
  }

  .about-accordion .accordion-button::after {
    position: absolute;
    right: 10px;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .view-button-form {
    flex-direction: column;
  }

  .view-button-form .btn {
    margin-bottom: 10px;
  }
  .bottom-navbar .col-md-3 {
    max-width: 50%;
  }
  .Customers-iteam {
    flex-wrap: unset;
    margin-bottom: 8px;
  }
  .navbar-brand img {
    width: 70px;
  }
  .top-nav {
    padding: 8px 30px;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .plate_no-card h2 {
    font-size: 40px;
    padding: 20px 15px;
  }

  .plate_no-detals a {
    font-size: 20px;
  }

  .plate_no-detals h4 {
    font-size: 20px;
  }

  .featured-page {
    margin-top: 60px;
  }
  .banner-serch-bar-title h2 {
    font-size: 24px;
    line-height: inherit;
    margin-bottom: 17px;
  }
  .call-nav-center h3 {
    font-size: 10px;
  }

  .navbar-toggler {
    margin-right: 51px;
    background-color: #fff;
  }

  .Customers-iteam h4 {
    padding-left: 4px;
    font-size: 10px;
  }

  .home-banner {
    padding: 60px 0px;
  }

  .banner-serch-bar .form-group input {
    height: 60px;
    font-size: 16px;
  }

  .banner-serch-bar .form-group button {
    height: 47px;
    font-size: 16px;
  }

  .view-number .got-button {
    font-size: 40px;
  }

  .view-button {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-button button {
    font-size: 14px;
    padding: 12px 15px;
  }

  .nik-button button {
    padding: 12px 14px;
    font-size: 23px;
  }

  .premium-table-head ul li:last-child h2 {
    text-align: center;
  }

  .premium-registrations {
    padding: 50px 0px;
  }

  .footer-logo ul li span {
    width: 35px;
    height: 35px;
  }

  .quick-links h2 {
    font-size: 18px;
  }

  .footer {
    padding: 60px 10px;
  }

  .quick-links ul li a {
    font-size: 13px;
    line-height: inherit;
  }

  .footer-logo p {
    font-size: 13px;
  }
  .navbar-right li.nav-item {
    position: relative;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 12px 41px;
    border-radius: 50%;
  }

  .navbar-right .cart-nav-bg {
    right: -32px;
    top: -8px;
  }
  .view-number .view-button {
    display: initial;
  }
  .audi-img img {
    width: 100%;
  }

  .management-card h2 {
    background-size: 100% 100%;
    font-size: 13px;
    width: 87%;
    padding: 20px 15px;
  }

  .management-card-box {
    padding: 30px 15px;
  }

  .management-card-box ul li a {
    font-size: 14px;
    margin-right: 15px;
  }

  .management-card-box p {
    font-size: 15px;
  }

  .management-card-box ul {
    padding-top: 15px;
    margin-top: 20px;
  }

  .management-plate {
    padding: 70px 0px;
  }

  .customer-card {
    padding: 20px;
  }

  .accordion-button {
    font-size: 18px;
  }

  .about-title h2 {
    font-size: 25px;
  }

  .visa-img img {
    width: 100%;
  }

  .view-button-form button {
    padding: 16px 22px;
    font-size: 16px;
  }

  .view-form-head h2 {
    font-size: 40px;
  }

  .view-form-head p {
    padding: 10px;
    font-size: 16px;
  }

  .view-form-body ul li h2 {
    font-size: 15px;
  }

  .view-form-body ul li {
    padding: 15px 10px;
  }

  .view-form-body ul li span {
    font-size: 15px;
  }

  .view-form-body .total-free h2 {
    font-size: 20px;
  }

  .view-form-body .total-free span {
    font-size: 20px;
  }

  .audi-img h3 {
    font-size: 14px;
  }
  .view-banner {
    padding-top: 50px;
    padding-bottom: 0px;
  }
  .view-form {
    padding: 15px;
  }
  .sell-card-row h3 {
    font-size: 25px;
  }

  .sell-registration-form h1 {
    font-size: 25px;
  }

  .sell-card-row h2 {
    font-size: 25px;
  }
  /*********checkout********/
  .secure-tabs .accordion-body {
    padding: 30px 15px;
  }

  .biling-form .form-control {
    height: 45px;
  }

  .biling-form .form-group {
    margin-bottom: 15px;
  }

  .biling-form h2 {
    font-size: 18px;
  }

  .biling-form .biling-title label {
    font-size: 13px;
  }

  .back-button button {
    padding: 12px 6px;
    font-size: 17px;
  }

  .optional-extras h2 {
    font-size: 18px;
  }

  .optional-extras .optional-button label {
    font-size: 15px;
    margin-left: 7px;
  }

  .optional-extras .optional-button {
    margin-top: 25px;
  }

  .optional-extras .optional-button h3 {
    font-size: 14px;
  }

  .order-summary-title h2 {
    font-size: 18px;
  }

  .order-summary-list li {
    padding: 10px 0px;
  }

  .order-summary-list {
    margin: 20px 0px;
  }

  .debit-card .form-check label {
    font-size: 16px;
  }

  .debit-card .form-check {
    width: 413px;
  }

  .debit-card .form-check input {
    margin-right: 10px;
  }

  .order-checkout-card-title h2 {
    font-size: 30px;
    margin-bottom: 20px;
  }

  .biling-form .form-select {
    height: 45px;
  }

  .secure-tabs .accordion-button span {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    font-size: 20px;
  }
  .order-checkout-card {
    padding: 10px;
  }
  .nomis-row .col-md-5 {
    width: 38%;
  }

  .nomis-row .col-md-2 {
    width: 23%;
  }

  .debit-card {
    margin-top: 20px;
  }

  .debit-numbar ul li input {
    height: 45px;
  }

  .debit-numbar ul {
    height: 45px;
    margin-bottom: 30px;
  }
  .contact-numbar h2 {
    font-size: 20px;
  }

  .make-offer-title h2 {
    font-size: 25px;
  }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1024px) {
  .navbar .nav-item {
    padding: 0 0px;
  }

  .navbar-brand {
    padding: 10px 10px;
  }
}

@media screen and (min-device-width: 1025px) and (max-device-width: 1200px) {
  .plate_no-card h2 {
    font-size: 40px;
    padding: 20px 15px;
  }

  .plate_no-detals a {
    font-size: 20px;
  }

  .plate_no-detals h4 {
    font-size: 20px;
  }

  .featured-page {
    margin-top: 60px;
  }
  .banner-serch-bar-title h2 {
    font-size: 33px;
    line-height: inherit;
  }
  .navbar .nav-item {
    padding: 0 0px;
  }

  .navbar-brand {
    padding: 10px 20px;
  }

  .navbar .nav-link {
    font-size: 13px;
  }

  .navbar-right li.nav-item {
    width: 8%;
  }

  .home-banner {
    padding: 50px 0px;
  }

  .banner-serch-bar .form-group input {
    height: 60px;
    font-size: 20px;
  }

  .banner-serch-bar .form-group button {
    height: 47px;
    font-size: 16px;
  }

  .premium-registrations {
    padding: 50px 0px;
  }

  .view-number .got-button {
    padding: 16px 40px;
    font-size: 40px;
  }
  .quick-links ul li a {
    font-size: 12px;
    line-height: normal;
}
.quick-links h2 {
  font-size: 16px;
}
.footer-logo p {
  font-size: 14px;
}

  .footer {
    padding: 50px 30px;
  }
  .audi-img img {
    width: 100%;
  }

  .audi-img h3 {
    font-size: 20px;
  }

  .view-form-head h2 {
    font-size: 40px;
  }

  .view-form-head p {
    padding: 10px;
    font-size: 16px;
  }

  .visa-img img {
    width: 100%;
  }

  .view-button-form button {
    padding: 16px 35px;
    font-size: 16px;
  }

  .view-form-body .total-free h2 {
    font-size: 20px;
  }

  .view-form-body .total-free span {
    font-size: 20px;
  }

  .view-form-body ul li h2 {
    font-size: 16px;
  }

  .view-form-body ul li span {
    font-size: 16px;
  }

  .management-card h2 {
    width: 83%;
    background-size: 100% 100%;
    font-size: 16px;
    padding-left: 20px;
  }

  .management-card-box {
    padding: 20px 20px;
  }

  .management-card-box ul li a {
    margin-right: 30px;
    font-size: 18px;
  }

  .management-card-box ul {
    margin-top: 20px;
  }

  .accordion-button {
    font-size: 20px;
  }

  .customer-card {
    padding: 20px;
  }
}

@media screen and (min-device-width: 1201px) and (max-device-width: 1365px) {
  .quick-links ul li a {
    font-size: 15px;
}
  .audi-img h3 {
    font-size: 1.9rem;
    top: 66.1%;
  }
  .plate_no-card h2 {
    font-size: 49px;
  }
  .banner-serch-bar-title h2 {
    font-size: 40px;
    line-height: inherit;
  }

  .banner-serch-bar .form-group input {
    height: 50px;
    font-size: 14px;
  }

  .banner-serch-bar .form-group button {
    height: 38px;
    font-size: 16px;
  }
  .navbar .nav-item {
    padding: 0 4px;
  }

  .navbar-right li.nav-item {
    width: 8%;
  }
  .audi-img img {
    width: 100%;
  }

  .view-button-form button {
    font-size: 18px;
    padding: 18px 20px;
  }

  .view-form-head h2 {
    font-size: 40px;
  }

  .management-card-box ul li a {
    font-size: 18px;
    margin-right: 30px;
  }
}

@media screen and (min-device-width: 1366px) and (max-device-width: 1900px) {
  .audi-img h3 {
    font-size: 1.9rem;
    top: 66.1%;
  }
  .plate_no-card h2 {
    font-size: 50px;
  }
  .banner-serch-bar-title h2 {
    font-size: 40px;
  }

  .banner-serch-bar .form-group input {
    height: 50px;
    font-size: 14px;
  }

  .banner-serch-bar .form-group button {
    height: 37px;
    font-size: 15px;
    width: 125px;
  }
  .navbar .nav-item {
    padding: 0 10px;
  }

  .navbar-right li.nav-item {
    width: 8%;
  }
  .audi-img img {
    width: 100%;
  }

  .management-card-box ul li a {
    margin-right: 33px;
    font-size: 18px;
  }
  .view-button-form button {
    padding: 21px 42px;
  }
}
